.therapist-row {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.therapist-profile img {
  width: 16rem;
  height: 16rem;
  border-radius: 1rem;
  object-fit: cover;
  object-position: center;
}

.therapist-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.625rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;
}

.therapist-designation {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;

  margin-bottom: 1rem;
}

.therapist-info {
  padding: 0px 1.5rem;
}
/* 
.therapist-info .star-rating-container {
  width: min-content;
  margin-bottom: 1.5rem;
} */

.therapist-info-field {
  margin-top: 1rem;
}

.therapist-info-field-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;

  margin-right: 1.5rem;
}

.therapist-info-field-value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;
}
