.assessment-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.assessment-container-heading {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  color: rgba(40, 40, 96, 1);
}
