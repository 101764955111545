.report-page-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 2rem;
  max-width: 1920px;
  max-height: 1000px;
  height: 100%;
  width: 100%;
}

.report-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;
}

.report-content-container {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  box-shadow: 0px 8px 16px rgba(41, 40, 45, 0.02);
  border-radius: 1.5rem;
  flex: 1 1 auto;
  overflow-y: overlay;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.report-content-tab-selector-header {
  padding: 1.5rem 2.5rem 0px 2.5rem;
  border-bottom: 1px solid rgba(40, 40, 96, 0.15);
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
}

.report-content-tab-selector {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  text-align: center;

  color: #282860;

  padding-bottom: 0.5rem;

  opacity: 0.5;
  /* margin-right: 60px; */
  cursor: pointer;
  text-decoration: none;
}

.report-content-tab-selector.active {
  opacity: 1;
  border-bottom: 2px solid #282860;
}

.report-content-section {
  width: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  flex: 1 1;
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 0px 0px 1.5rem 1.5rem;
  padding: 2rem;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

@media screen and (max-width: 1440px) {
  .report-page-wrapper {
    height: 100vh;
  }
}
