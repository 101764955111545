.dashboard-wrapper {
  padding: 2rem;
  max-width: 1920px;
  max-height: 1000px;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
  height: 100%;
  width: 100%;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 2rem;
  row-gap: 2rem;
}

.dashboard-left-wrapper {
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.client-dashboard-grid {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2rem;
}

@media screen and (max-width: 1440px) {
  .dashboard-wrapper {
    height: unset;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}
