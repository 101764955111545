.assessment-item {
  width: 30%;
  height: 170px;
  margin: 10px;
  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  border: 1px solid rgba(40, 40, 96, 0.25);
  border-radius: 16px;
  cursor: pointer;
}

.assessment-item-missing {
  background-color: rgba(236, 116, 64, 0.1);
}

.assessement-item-image {
  width: 30px;
}

.assessement-item-label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  color: rgba(40, 40, 96, 1);
}

.assessement-item-description {
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
}

.assessement-item-take-action {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 20px;
}

.assessement-item-take-action-date {
  display: flex;
  align-items: center;

  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: rgba(40, 40, 96, 1);
}

.assessement-item-take-action-date > img {
  margin-right: 5px;
  width: 20px;
}

.assessement-item-take-action-date-missed {
  color: rgba(236, 116, 64, 1);
}

.assessement-item-take-action-button {
  width: 100px;
  height: 40px;

  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  text-align: center;

  background: rgba(95, 108, 225, 1);
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
}

.assessement-item-take-action-button-missed {
  background: rgba(236, 116, 64, 1);
}
