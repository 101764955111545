.session-item-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  cursor: pointer;
}
.session-item-date-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 2.5rem;
  gap: 0.5rem;
  /* flex: 1 0 auto; */
  min-width: 11rem;
  padding-left: 1rem;
  width: max-content;
}
.session-item-date {
  font-size: 1.25rem;
  font-weight: 600;
}

.session-item-time {
  font-size: 1rem;
  font-weight: 500;
}

.session-item-under-weather-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  padding: 1rem 2.5rem;
  border-left: 4px rgba(40, 40, 96, 0.3) solid;
  border-right: 4px rgba(40, 40, 96, 0.3) solid;
  max-width: 17rem;
}

.session-item-under-weather-heading {
  font-size: 1.25rem;
  font-weight: 600;
}
.session-item-under-weather-icon {
  width: 4rem;
  height: 4rem;
}

.session-item-under-weather-icon img {
  width: 4rem;
  height: 4rem;
  object-position: center;
  object-fit: contain;
}

.session-item-summary-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem 2.5rem;
  border-right: 4px rgba(40, 40, 96, 0.3) solid;
  gap: 0.5rem;
  flex: 1 0 auto;
}

.session-item-summary-heading {
  font-size: 1.25rem;
  font-weight: 600;
}

.session-item-summary-body {
  font-size: 1rem;
  font-weight: 500;
}

.session-item-edit-icon-wrapper {
  display: flex;
  gap: 1rem;
  padding: 1rem 2.5rem;
  align-items: center;
  padding-right: 0;
}

.session-item-edit-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.session-item-edit-icon img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
  object-position: center;
}
