.report-files-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.report-files-header-infotext {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.5;
}

.report-files-header-add-files-button {
  background: #5f6ce1;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  cursor: pointer;
}

.report-files-header-add-files-button img {
  margin-right: 0.5rem;
}

.report-files-header {
  margin-bottom: 1.5rem;
}

.report-files-all-files-section {
  margin-top: 40px;
}

.report-both-pinned-and-pinned-files {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.report-both-pinned-and-pinned-files .MagnifyingGlass-wrapper {
  flex: 1 1 auto;
  align-self: center;
  /* width: 100%; */
}
