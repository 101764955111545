.client-blogs {
  min-height: 20rem;
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  box-shadow: 0px 8px 16px rgba(41, 40, 45, 0.02);
  border-radius: 1.5rem;
  /* margin-top: 2rem; */
  padding: 1.5rem;
}

.blog-subheading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;

  display: flex;
  align-items: center;

  color: #282860;
  margin-bottom: 1.2rem;
}

.blogs-row {
  /* margin-top: 1.5rem; */
  overflow-y: auto;
  contain: strict;
  height: 100%;
  /* width: 1015px; */
  /* white-space: nowrap; */
  /* display: flex; */
  /* flex-wrap: wrap; */
  
  /* gap: 2rem; */
}

.client-blog {
  /* height: 210px;
  width: 240px; */
  display: inline-block;
  max-width: 15rem;
}

.client-blog-banner {
  width: 100%;
  cursor: pointer;
}

.client-blog-banner img {
  width: 15rem;
  border-radius: 1.25rem;
}

.client-blog-heading {
  /* height: 54px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  /* line-height: 21px; */
  /* identical to box height */

  color: #282860;

  opacity: 0.8;
  padding: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
