.invited-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 26px;

  height: 100%;
}

.invited-text {
  width: 70%;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.invited-button {
  margin-top: 30px;
  width: 150px;
  height: 60px;
  color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.invite {
  background-color: #5f6ce1;
}

.invited {
  background-color: #10e056;
}
