.assessment-questions-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.assessmenent-question-list-container {
  width: 100%;
}

.assessmenent-question-list-heading {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  color: rgba(40, 40, 96, 1);
  margin-bottom: 10px;
  display: flex;
}

.assessmenent-question-list-back-button {
  margin-right: 10px;
  cursor: pointer;
}

.assessmenent-question-list-subheading {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: rgba(40, 40, 96, 0.7);
  margin-bottom: 10px;
  margin-bottom: 20px;
}

.assessmenent-question-item {
  display: flex;
  /* height: 100px; */
  margin-top: 30px;
}

.assessmenent-question-item-question-order {
  margin-right: 40px;
}

.assessmenent-question-item-question-order > div {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border-radius: 20px;

  background-color: rgba(95, 108, 225, 1);
  color: white;

  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.assessmenent-question-item-question-and-option {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.assessmenent-question-item-question {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  max-width: 100ch;
}

.assessmenent-question-item-option-container {
  display: flex;
  width: 100%;
  margin-top: 5px;
}

.assessmenent-question-item-option {
  border: 1px solid rgba(221, 226, 233, 1);
  /* height: 30px; */
  padding: 10px;
  margin-right: 20px;
  border-radius: 8px;
  background-color: rgba(221, 226, 233, 0.15);
  text-align: center;

  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  cursor: pointer;
}

.assessmenent-question-list-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.assessmenent-question-list-button {
  /* height: 40px; */
  padding: 10px;
  background: rgba(95, 108, 225, 1);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;

  cursor: pointer;
}

.result-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.result-modal-container-heading {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
}

.result-modal-container-score {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.result-modal-container-score > span {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
}

.result-modal-container-submit-button {
  background: rgba(95, 108, 225, 0.9);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: white;
  padding: 5px;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
}
