.setting-list-wrapper {
  grid-area: setting-list;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  border: 1px solid rgba(50, 51, 55, 0.1);
  box-shadow: 0px 8px 16px rgba(41, 40, 45, 0.04);
  border-radius: 32px;
  padding: 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
}

.settings-accounts-div {
  display: flex;
  flex-direction: column;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.settings-list-option {
  display: flex;
  flex-direction: row;
  border-radius: 0.2rem;

  padding: 0.4rem 0.8rem;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  cursor: pointer;
}

.settings-list-option-selected {
  display: flex;
  flex-direction: row;
  border-radius: 0.8rem;
  background-color: #5f6ce1;

  color: white;
  padding: 0.4rem 0.8rem;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  cursor: pointer;
}

.settings-list-option > div,
.settings-list-option-selected > div {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.settings-list-option > div {
  background: rgb(241, 241, 241);
}

.settings-list-option-selected > div {
  background: #939dff;
}

.settings-option-img {
  height: 2.4rem;
  width: 2.4rem;
}

.settings-option-icon {
  height: 1.5rem;
  width: 1.5rem;
}
