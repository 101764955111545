.cameraView {
  height: 100%;
  width: 100%;
  /* border-radius: 1rem; */
  border: 1px solid rgba(44, 50, 84, 0.15);
  object-fit: cover;
  /* contain: strict; */
}

.recordButton {
  color: #fff;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
}

