.realtime-ai-assesement-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: auto;
}

.realtime-ai-assesement-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  color: #282860;
  margin-bottom: 40px;
}

.realtime-ai-assesement-description {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #282860;
  margin-bottom: 60px;
}

.realtime-ai-assesement-result-container {
  width: 100%;
  height: 150px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.realtime-ai-assesement-result {
  position: relative;
}

.realtime-ai-assesement-result-chart {}

.realtime-ai-assesement-result-score-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.realtime-ai-assesement-result-score {
  font-family: Roboto;
  font-size: 40px;
  font-weight: 700;
  line-height: 22px;
  color: #282860;
}

.realtime-ai-assesement-explanation {
  width: 90%;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  border-radius: 16px;
  border: 1px;
  background: #FBF0EB;
  padding: 40px;
  margin-bottom: 60px;
}

.realtime-ai-assesement-cta-container {
  display: flex;
  justify-content: center;
  gap: 150px;
  margin-bottom: 60px;
}

.realtime-ai-assesement-cta-find-therapist {
  width: 204px;
  height: 56px;
  border-radius: 8px;
  border: 1px;
  border: 1px solid #5F6CE1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.realtime-ai-assesement-cta-another-conversation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.realtime-ai-assesement-cta-another-conversation-main {
  width: 259px;
  height: 56px;
  border-radius: 8px;
  opacity: 0px;
  background: #5F6CE1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFF;
  cursor: pointer;
}

.realtime-ai-assesement-cta-another-conversation-descriptoin {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #282860;
  display: none;

}

@media (max-width: 768px) {
  .realtime-ai-assesement-cta-container {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}