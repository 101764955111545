.reschedule-modal-input-row {
  display: grid;

  grid-template-columns: 1fr 2fr;
  column-gap: 52px;
}

.reschedule-modal-reschedule-button {
  background: #5F6CE1;
  border-radius: 8px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
  text-align: center;

  color: #FFFFFF;
  padding: 8px 24px;
  margin-top: 40px;
  cursor: pointer;
}

.time-picker-row {
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  column-gap: 16px;
}

.seperator {
  display: flex;
  align-items: center;
}