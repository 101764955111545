.settings-wrapper {
  display: grid;
  width: calc(100%);
  height: calc(100%);
  padding: 1rem;

  grid-template-areas: "setting-list setting-details";
  gap: 1.5rem;

  grid-template-columns: 1.1fr 4fr;
  max-width: 1920px;
  max-height: 1000px;
}

@media screen and (max-width: 1440px) {
  .settings-wrapper {
    height: 100vh;
  }
}
