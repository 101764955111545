.add-file-content {
  border-radius: 24px;
  border: 1px dashed rgba(40, 40, 96, 0.25);
  background: rgba(171, 178, 242, 0.1);
  height: 705px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.add-file-content.dragging-file {
  background: rgba(171, 178, 242, 0.2);
  border: 1px dashed rgba(40, 40, 96, 0.5);
}

.report-files-header-add-files-button {
  margin: 16px 0px;
}

.add-file-content-info-text {
  color: #282860;
  text-align: center;
  font-size: 16px;
  height: 24px;
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.32px;
  opacity: 0.5;
}

.overview-pinned-file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overview-pinned-file.upload-file {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 220px;
}

.report-files {
  display: flex;
}

#uploadInput {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.uploaded-file .overview-pinned-file {
  background-color: rgba(0, 255, 26, 0.1);
}

.file-upload-back-button {
  position: absolute;
  padding: 1rem;
  top: 0;
  right: 0;
}
