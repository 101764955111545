.report-session-container {
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 7fr 9fr; */
  gap: 2rem;
}

.report-session-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.report-session-back-btn {
  cursor: pointer;
}

.report-session-heading {
  /* height: 40px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  /* line-height: 150%; */
  /* or 27px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  margin-left: 1.25rem;
}

.session-trigger-map-heading {
  font-weight: 500;
  font-size: 1rem;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;
}

.session-video {
  margin-top: 0.75rem;
  flex: 1 1;
  align-self: center;
}

.session-video video {
  width: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 0.75rem;
}

.session-trigger-map-heading {
  margin-top: 6px;
  margin-right: 16px;
}

.session-summary {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.session-summary-header img {
  cursor: pointer;
}

.session-summary-text {
  /* height: 32px; */
  font-weight: 500;
  font-size: 1rem;

  display: flex;
  align-items: center;

  color: #282860;
}

.session-summary-container {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem;
  padding: 1.5rem;
  font-weight: 500;
  font-size: 0.875rem;

  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;
  flex: 1 1 auto;
}

.session-trigger-map-header {
  position: relative;
}

.session-trigger-map-info-popup {
  border-radius: 1.5rem;
  border: 0.745px solid rgba(50, 51, 55, 0.1);
  background: #fff;
  padding: 1.5rem;
  width: 20rem;

  color: rgba(40, 40, 96, 0.7);
  font-size: 0.875rem;
  font-family: "Poppins";
  font-weight: 500;

  position: absolute;
  z-index: 1000;
  top: 2.5rem;
  left: 5rem;
}

.report-session-video-and-summary {
  display: flex;
  gap: 2rem;
  width: 100%;
  max-height: 25rem;
}

.session-video {
  flex: 1;
  align-self: center;
  max-height: 25rem;
}
.session-video video {
  width: 100%;
  max-height: 25rem;
  object-fit: contain;
  object-position: center;
}

.session-trigger-map {
  flex: 1 1 auto;
  width: 100%;
  /* overflow-x: scroll; */
  position: relative;
}
