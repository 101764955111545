.assessment-questions-parent-container {
  display: flex;
  padding: 5%;
  justify-content: center;
}

.avatar-parent-container {
  width: 100%;
  height: 100%;
  padding: 5%;
}