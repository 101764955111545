.rename-file-input-field {
  width: 100%;
}

.rename-btn {
  align-items: center;
  background: #5F6CE1;
  border-radius: 8px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  padding: 8px 24px;
  text-align: center;
  height: 40px;
}

.cancel-btn {
  margin-left: 20px;
  background-color: rgba(95, 108, 225, 0.1);
  color: rgba(95, 108, 225, 1);
  border: 1px solid rgba(40, 40, 96, 0.15);
}

.rename-file-btn-group {
  margin-top: 20px;
  justify-content: flex-end;
}