.edit-session-summary-modal-content {
  width: 944px;
}

.edit-session-modal-summary {
  border-radius: 24px;
  border: 1px solid rgba(40, 40, 96, 0.15);
  background: #F6F7FF;
  padding: 32px;
  color: #282860;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.28px;
  width: 100%;
  height: 600px;
  outline: none;
}

.edit-session-summary-modal-header {
  padding-bottom: 0px;
}

.edit-session-summary-modal-body {
  padding-top: 24px;
}

.edit-session-summary-modal-save-btn {
  border-radius: 8px;
  background: #5F6CE1;
  padding: 8px 24px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  display: inline-block;

  margin-top: 40px;
  cursor: pointer;
}