.profile-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profile-display {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2.8rem;
}
.profile-display:hover::after {
  content: "Edit";
}

.profile-display-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
}

.image-cropper {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.profile-display-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
}

.profile-display-info .heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 180%;
  /* or 36px */

  display: flex;
  align-items: center;

  color: #282860;
}

.profile-display-info .info-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  /* or 29px */

  display: flex;
  align-items: center;

  color: #575757;
}

.settings-input-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
  padding-top: 1.2rem;
}

.normal-input {
  width: 24rem;
  height: 2.8rem;
  border-radius: 0.8rem;
  border: 2px solid #aaa;
  padding: 0rem 0.8rem;
}

.normal-dropdown {
  width: 24rem;
  height: 2.72rem;
  border: solid 1.4px #aaa;
  border-radius: 2rem;
}

.settings-input-pair-div {
  display: flex;
  flex-direction: column;
  margin: 1.2rem 0;
  gap: 0.4rem;
}

.sized-box {
  height: 2.4rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2.4rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.8rem;
}

.blue-button {
  height: 3rem;
  width: 12rem;
  background-color: #5f6ce1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
  color: white;
}

.profilepic-update-menu {
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: center;
  gap: 1.2rem;
  padding-left: 4.4rem;
}

.profilepic-update-menu input {
  display: none;
}

.yellow-button {
  height: 3rem;
  width: 12rem;
  background-color: #f1d07c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
}

.profile-picture-big {
  width: 100px;
  height: 100px;
}

.profile-root .MuiInputBase-root {
  height: 2.72rem;
  border: solid 1.4px #aaa;
}
