.videoPermissionNotification {
  position: absolute;
  top: 5%;
  left: 30%;
  /* display: flex; */
  display: none; /* Set it to none for now, in preparation for potentially displaying it in the future.  */
  gap: 16px;

  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 70%;
  letter-spacing: 0.02rem;
}

.avatar-camera-container {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 30px;
  position: relative;
}

.avatar-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.camera-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.camera-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  border: 1px solid #EAEAEA;
  background: #EAEAEA;
}

.camera-main {
  height: 100%;
  width: 100%;
}

.camera-control-container {
  position: absolute;
  bottom: 20px;
  z-index: 9999;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.camera-control-panel {
  /* position: absolute;
  right: 20%;
  bottom: 0; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 8px;
  gap: 20px;
  min-width: 160px;
}

.camera-control-button {
  width: 40px;
  height: 40px;
  background: #DDE2E94D;
  border: 1px solid #28286026;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-control-button-icon{
  width: 16px;
  height: 16px;
}

.camera-control-button-on {
  background: #282860;
}

.camera-control-button:hover {
  background-color: #cccccc;
}

.push-to-talk {
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.avatar-transcripts {
  flex-direction: row;
  gap: 30px;
}

@media (max-width: 768px) {
  .avatar-camera-container {
    flex-direction: column;
    position: relative;
  }

  .camera-container {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 100%;
    height: 50%;
    align-items: flex-end;
    flex-direction: column;
  }

  .camera-wrapper {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: flex-end;
    gap: 20px;
    border: none;
    background: none;
  }

  .camera-main {
    height: 50%;
    width: 50%;
  }

  .camera-control-container {
    width: 100%;
    position: relative;
    align-items: center;
    bottom: 0px;

  }

  .push-to-talk {
    height: 50px;
    min-width: 120px;
    font-size: 1.5rem;
  }

  .avatar-transcripts {
    height: 200px;
    flex-direction: column-reverse;
    gap: 10px;
  }
}