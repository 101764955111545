.overview-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  margin-bottom: 1.5rem;
}

.overview-latest-activity-logs {
  max-height: 30rem;
  overflow-y: overlay;
  overflow-x: hidden;
}

.overview-latest-activity-log {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  color: #282860;

  opacity: 0.7;
  margin-left: 0.5rem;
}

.over-latest-activity {
  align-items: center;
  gap: 0.5rem;
}

.over-latest-activity > img {
  width: 3rem;
  height: 3rem;
}

.overview-show-all {
  height: 1.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  /* or 32px */

  display: flex;
  align-items: center;

  color: #5f6ce1;
  cursor: pointer;
}

.activity-log-info-text {
  opacity: 0.7;
}

.activity-log-timestamp {
  line-height: 0.875rem;
}

.overview-latest-activity-seperator {
  width: 2px;
  height: 1.5rem;
  background: #e4e7fd;
  border-radius: 2px;
  margin-left: 1.5rem;
  transform: translateX(-1px);
}

.no-activities-info {
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-activities-info > div {
  font-style: italic;
  opacity: 0.7;
}
